<template>
  <a-layout class="custom-layout">
    <a-layout-sider class="custom-sider">
      <group-menu></group-menu>
    </a-layout-sider>
    <a-layout-content class="custom-content auto" style="position:relative;">
      <a-spin :spinning="loading">
        <div class="drawer-bar auto">
          <span class="font16"><b>筛选器管理</b></span>
        </div>
        <div class="department-content auto">
          <div class="depart-box depart-l fl">
            <div class="left-top auto">
              <a-input-search v-model="searchValue" placeholder="请输入关键字" size="small" style="width: 200px; float: left;" allowClear />
              <a href="javascript:;" class="fr" style="margin-top: 1px;" @click="addFilter">添加</a>
            </div>
            <div class="left-con auto" :style="'minHeight:' + boxHeight + 'px'">
              <ul>
                <li v-for="(item, index) in listData" :key="index" @click="chooseList(item)" :class="current_row.id === item.id ? 'cur' : ''" v-show="item.name.indexOf(searchValue) > -1">
                  <span class="fl icon" v-if="item.type === 'standard'"><svg t="1715001993063" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3020" width="16" height="16"><path d="M794.68920898 175.81835937a19.77539063 19.77539063 0 0 1 12.85400391 34.85412599L587.0970459 398.24206542a49.43847656 49.43847656 0 0 0-17.35290527 37.82043458l1.38427734 402.03369141a24.71923828 24.71923828 0 0 1-39.55078125 19.8248291l-98.58032227-73.91052246a49.43847656 49.43847656 0 0 1-19.77539062-39.79797364l1.4831543-308.10058593a49.43847656 49.43847656 0 0 0-17.40234375-37.86987305L176.90600586 210.67248535a19.77539063 19.77539063 0 0 1 12.85400391-34.85412597h604.92919921zM828.40625 749.3046875a39.55078125 39.55078125 0 0 1 4.59777833 78.85437012l-4.59777833 0.24719238H650.42773438a39.55078125 39.55078125 0 0 1-4.59777833-78.85437012l4.59777832-0.24719238h177.97851563z m0-158.203125a39.55078125 39.55078125 0 0 1 4.59777833 78.85437012l-4.59777833 0.24719238H650.42773438a39.55078125 39.55078125 0 0 1-4.59777833-78.85437012l4.59777832-0.24719238h177.97851563z m0-158.203125a39.55078125 39.55078125 0 0 1 4.59777833 78.85437012l-4.59777833 0.24719238H650.42773438a39.55078125 39.55078125 0 0 1-4.59777833-78.85437012l4.59777832-0.24719238h177.97851563z" p-id="3021" fill="#91D5FF"></path></svg></span>
                  <span class="fl icon" v-else><svg t="1715001993063" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3020" width="16" height="16"><path d="M794.68920898 175.81835937a19.77539063 19.77539063 0 0 1 12.85400391 34.85412599L587.0970459 398.24206542a49.43847656 49.43847656 0 0 0-17.35290527 37.82043458l1.38427734 402.03369141a24.71923828 24.71923828 0 0 1-39.55078125 19.8248291l-98.58032227-73.91052246a49.43847656 49.43847656 0 0 1-19.77539062-39.79797364l1.4831543-308.10058593a49.43847656 49.43847656 0 0 0-17.40234375-37.86987305L176.90600586 210.67248535a19.77539063 19.77539063 0 0 1 12.85400391-34.85412597h604.92919921zM828.40625 749.3046875a39.55078125 39.55078125 0 0 1 4.59777833 78.85437012l-4.59777833 0.24719238H650.42773438a39.55078125 39.55078125 0 0 1-4.59777833-78.85437012l4.59777832-0.24719238h177.97851563z m0-158.203125a39.55078125 39.55078125 0 0 1 4.59777833 78.85437012l-4.59777833 0.24719238H650.42773438a39.55078125 39.55078125 0 0 1-4.59777833-78.85437012l4.59777832-0.24719238h177.97851563z m0-158.203125a39.55078125 39.55078125 0 0 1 4.59777833 78.85437012l-4.59777833 0.24719238H650.42773438a39.55078125 39.55078125 0 0 1-4.59777833-78.85437012l4.59777832-0.24719238h177.97851563z" p-id="3021" fill="#B7EB8F"></path></svg></span>
                  <span class="fl" :title="item.name">{{item.name}}</span>
                  <a-popconfirm
                    title="确定要删除吗?"
                    ok-text="确定"
                    cancel-text="取消"
                    placement="topRight"
                    @confirm="deleteFilter(item)"
                  >
                    <a-icon type="delete" class="fr" @click.stop />
                  </a-popconfirm>
                </li>
              </ul>
            </div>
          </div>
          <div class="depart-box depart-r fl" v-if="current_row.id || isAdd">
            <div class="depart-con auto">
              <div id="no-margin-form" class="right-form">
                <a-form-model
                    id="filter-form"
                    layout="inline"
                    ref="itemForm"
                    :model="itemForm"
                    class="ant-advanced-search-form"
                    :label-col="{span: 3}"
                    :wrapper-col="{span: 21}"
                  >
                  <a-form-model-item label="筛选器名称" prop="name">
                    <a-input v-model="itemForm.name" placeholder="请输入名称" size="small" style="width:100%;"/>
                  </a-form-model-item>
                  <a-form-model-item label="筛选器类型" prop="type">
                    <a-radio-group :options="addOptions" v-model="itemForm.type" :disabled="true" />
                  </a-form-model-item>
                  <a-form-model-item label="筛选器公式" prop="expressionName">
                    <a-input type="textarea" v-model="itemForm.expressionName" readOnly placeholder="" size="small" style="width:100%;"/>
                  </a-form-model-item>
                  <a-form-model-item label="" style="margin-top: 5px;" :wrapper-col="{span: 24}">
                    <div class="inside-box auto boxstyle">
                      <div class="box-left auto">
                        <div class="relationbox auto" v-if="itemForm.expressionContent.c.length > 1">
                          条件关系：
                          <a-radio-group button-style="solid" v-model="itemForm.expressionContent.l" size="small">
                            <a-radio-button :value="item.id" v-for="(item, index) in relationOptions" :key="index">
                              {{item.name}}
                            </a-radio-button>
                          </a-radio-group>
                        </div>
                        <div class="inside-box auto" style="margin: 5px 0;" v-for="(item, index) in itemForm.expressionContent.c" :key="index">
                          <div class="box-left auto boxstyle groupbox">
                            <div class="out-box auto">
                              <div class="relationbox auto" v-show="itemForm.expressionContent.c[index].c.length > 1">
                                条件关系：
                                <a-radio-group button-style="solid" v-model="itemForm.expressionContent.c[index].l" size="small">
                                  <a-radio-button :value="item.id" v-for="(item, index) in relationOptions" :key="index">
                                    {{item.name}}
                                  </a-radio-button>
                                </a-radio-group>
                              </div>
                              <div class="inside-box auto" style="margin: 5px 0;" v-for="(midItem, midIndex) in itemForm.expressionContent.c[index].c" :key="midIndex">
                                <div class="box-left auto">
                                  <div class="mid-box auto boxstyle">
                                    <div class="mid-left auto">
                                      <div class="relationbox auto">
                                        条件关系：
                                        <a-radio-group button-style="solid" v-model="itemForm.expressionContent.c[index].c[midIndex].l" size="small">
                                          <a-radio-button :value="item.id" v-for="(item, index) in relationOptions" :key="index">
                                            {{item.name}}
                                          </a-radio-button>
                                        </a-radio-group>
                                      </div>
                                      <div class="inside-box auto" v-for="(insideItem, insideIndex) in itemForm.expressionContent.c[index].c[midIndex].c" :key="insideIndex">
                                        <div class="box-left fl">
                                          <div class="inside-item auto boxstyle">
                                            <div class="item-left auto fl">
                                              <a-select v-model="itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f']" size="small" style="width: calc(100% - 5px);" @change="changeType($event, index, midIndex, insideIndex)">
                                                <a-select-option :value="item.code" v-for="(item, itemIndex) in options['attributeentries']" :key="itemIndex">
                                                  {{item.name}}
                                                </a-select-option>
                                              </a-select>
                                            </div>
                                            <div class="item-left item-mid auto fl">
                                              <a-input placeholder="请输入运算符" v-model="itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['o']" type="text" size="small" v-if="options['attributeentries'].find(item => { return item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] }) && dateTyple.indexOf(itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f']) > -1" style="width: calc(100% - 5px);"></a-input>

                                              <a-select placeholder="请选择" v-model="itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['o']" size="small" style="width: calc(100% - 5px);" v-if="options['attributeentries'].find(item => { return item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] }) && dateTyple.indexOf(itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f']) < 0">
                                                <a-select-option :value="item.symbol" v-for="(item, itemIndex) in options['operations']" :key="itemIndex" :disabled="!!!options['attributetype'][options['attributeentries'].find(item => { return item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] })['type']]['operation'].find(item2 => { return item2 === item.code })" v-if="!!options['attributetype'][options['attributeentries'].find(item => { return item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] })['type']]['operation'].find(item2 => { return item2 === item.code })">
                                                  {{item.name}}
                                                </a-select-option>
                                              </a-select>
                                            </div>
                                            <div class="item-left item-right auto fl">

                                              <a-input placeholder="请输入数值" v-model="itemForm['expressionContent']['c'][index]['c'][midIndex]['c'][insideIndex]['v']" type="number" size="small" v-if="options['attributeentries'].find(item => { return item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] }) && options['attributeentries'].find(item => { return item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] })['type'] === 'number'" style="width:100%;"></a-input>

                                              <a-input placeholder="请输入内容" v-model="itemForm['expressionContent']['c'][index]['c'][midIndex]['c'][insideIndex]['v']" size="small" v-if="options['attributeentries'].find(item => { return item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] }) && options['attributeentries'].find(item => { return item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] })['type'] === 'runtime_attr'" style="width:100%;"></a-input>

                                              <a-input placeholder="请输入内容" v-model="itemForm['expressionContent']['c'][index]['c'][midIndex]['c'][insideIndex]['v']" size="small" v-if="options['attributeentries'].find(item => { return item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] }) && options['attributeentries'].find(item => { return item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] })['type'] === 'runtime_date'" style="width:100%;"></a-input>

                                              <a-input placeholder="请输入内容" v-model="itemForm['expressionContent']['c'][index]['c'][midIndex]['c'][insideIndex]['v']" size="small" v-if="options['attributeentries'].find(item => { return item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] }) && options['attributeentries'].find(item => { return item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] })['type'] === 'string'" style="width:100%;"></a-input>

                                              <a-date-picker valueFormat="YYYY-MM-DD" v-model="itemForm['expressionContent']['c'][index]['c'][midIndex]['c'][insideIndex]['v']" size="small" v-if="options['attributeentries'].find(item => { return item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] }) && options['attributeentries'].find(item => {return  item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] })['type'] === 'date'" style="width:100%;"><a-icon slot="suffixIcon" type="calendar" /></a-date-picker>

                                              <a-select size="small" placeholder="请选择" v-model="itemForm['expressionContent']['c'][index]['c'][midIndex]['c'][insideIndex]['v']" v-if="options['attributeentries'].find(item => { return item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] }) && options['attributeentries'].find(item => { return item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] })['dict'] && options['attributeentries'].find(item => { return item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] })['dict'] !== 'usertype' && options['attributeentries'].find(item => { return item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] })['dict'] !== 'department' && options['attributeentries'].find(item => { return item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] })['dict'] !== 'address'" style="width:100%;">
                                                <a-select-option :value="filterItem.id" v-for="(filterItem, filterIndex) in filters[options['attributeentries'].find(item => { return item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] })['dict']]" :key="filterIndex">
                                                  {{filterItem.name}}
                                                </a-select-option>
                                              </a-select>

                                              <el-cascader placeholder="请选择组织机构" v-model="itemForm['expressionContent']['c'][index]['c'][midIndex]['c'][insideIndex]['v']" v-if="(showDetail || isAdd) && options['attributeentries'].find(item => { return item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] }) && filters['department'] && options['attributeentries'].find(item => { return item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] })['dict'] && options['attributeentries'].find(item => { return item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] })['dict'] === 'department'" :show-all-levels="false" :props="{ checkStrictly: true, label: 'name', value: 'id', children: 'children', multiple: false, emitPath: false }" :options="filters['department']" size="mini" class="custom-el-cascader" clearable style="width:100%;"></el-cascader>

                                              <el-cascader placeholder="请选择人员类别" v-model="itemForm['expressionContent']['c'][index]['c'][midIndex]['c'][insideIndex]['v']" v-if="(showDetail || isAdd) && options['attributeentries'].find(item => { return item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] }) && filters['usertype'] && options['attributeentries'].find(item => { return item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] })['dict'] && options['attributeentries'].find(item => { return item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] })['dict'] === 'usertype'" :show-all-levels="false" :props="{ checkStrictly: true, label: 'name', value: 'id', children: 'children', multiple: false, emitPath: false }" :options="filters['usertype']" size="mini" class="custom-el-cascader" clearable style="width:100%;"></el-cascader>

                                              <el-cascader placeholder="请选择地区" v-model="itemForm['expressionContent']['c'][index]['c'][midIndex]['c'][insideIndex]['v']" v-if="(showDetail || isAdd) && options['attributeentries'].find(item => { return item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] }) && filters['address'] && options['attributeentries'].find(item => { return item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] })['dict'] && options['attributeentries'].find(item => { return item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] })['dict'] === 'address'" :show-all-levels="false" :props="{ checkStrictly: true, label: 'name', value: 'id', children: 'children', multiple: false, emitPath: false }" :options="filters['address']" size="mini" class="custom-el-cascader" clearable style="width:100%;"></el-cascader>

                                            </div>
                                          </div>
                                        </div>
                                        <div class="box-right fr">
                                          <a-icon v-show="itemForm.expressionContent.c[index].c[midIndex].c.length > 1" type="delete" class="delete-icon" @click="deleteInside(index, midIndex, insideIndex)" />
                                          <a-icon v-show="itemForm.expressionContent.c[index].c[midIndex].c.length <= 1" class="delete-icon-default" type="delete" />
                                        </div>
                                      </div>
                                      <a-button class="add-btn" type="dashed" size="small" @click="addInside(index, midIndex)" style="width: calc(100% - 30px);">+ 添加条件</a-button>
                                      <div class="box-right rightclose auto">
                                        <a-icon theme="filled" type="close-circle" v-show="itemForm.expressionContent.c[index].c.length > 1" class="delete-icon close-circle-icon" style="color: #FF4D4F;" @click="deleteMid(index, midIndex)" />
                                        <a-icon theme="filled" type="close-circle" v-show="itemForm.expressionContent.c[index].c.length <= 1" class="delete-icon-default close-circle-icon-default" />
                                  </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <a-button class="add-btn" type="dashed" size="small" @click="addMid(index)" style="width: calc(100%);">+ 添加条件</a-button>
                              <div class="box-right rightclose auto">
                                <a-icon theme="filled" type="close-circle" v-show="itemForm.expressionContent.c.length > 1" class="delete-icon close-circle-icon" style="color: #FF4D4F;" @click="deleteOut(index)" />
                                <a-icon theme="filled" type="close-circle" v-show="itemForm.expressionContent.c.length <= 1" class="delete-icon-default close-circle-icon-default" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <a-button class="add-btn" type="dashed" size="small" @click="addOut" style="width: calc(100%);">+ 添加条件</a-button>
                      </div>
                    </div>
                  </a-form-model-item>
                  <a-form-model-item label="" :wrapper-col="{span: 24}" style="margin-top:10px;text-align:center;">
                    <a-button type="primary" size="small" @click="submitFilter">保存</a-button>
                  </a-form-model-item>
                </a-form-model>
              </div>
            </div>
          </div>
        </div>
      </a-spin>
    </a-layout-content>
    <a-modal v-model="addVisible" title="添加筛选器" @cancel="addClose" width="400px">
      <a-form-model
          id="no-margin-form"
          layout="inline"
          :model="addTypeForm"
          class="ant-advanced-search-form"
          :label-col="{span: 6}"
          :wrapper-col="{span: 18}"
        >
        <a-form-model-item label="筛选器类型" prop="type">
          <a-radio-group :options="addOptions" v-model="addTypeForm.type" />
        </a-form-model-item>
      </a-form-model>
      <span slot="footer" class="dialog-footer">
        <a-button @click="addClose">取 消</a-button>
        <a-button type="primary" @click="addSubmit">确 定</a-button>
      </span>
    </a-modal>
  </a-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import { Group } from '@/model'
export default {
  data () {
    return {
      id: '',
      boxHeight: 0,
      loading: true,
      listData: [],
      searchValue: '',
      filters: {},
      departmentName: {},
      addressName: {},
      userTypeName: {},
      addVisible: false,
      addOptions: [
        {
          value: 'standard',
          label: '普通'
        },
        {
          value: 'runtime',
          label: '运行时'
        }
      ],
      addTypeForm: {
        type: 'standard'
      },
      itemForm: {
        name: '',
        type: 'standard',
        order: 0,
        expressionName: '',
        expressionContent: {
          '@type': 'Complex',
          'l': 'and',
          'c': [
            {
              '@type': 'Complex',
              'l': 'and',
              'c': [
                {
                  '@type': 'Complex',
                  'l': 'and',
                  'c': [
                    { '@type': 'Basic', 'f': 'idCardType', 'o': undefined, 'v': undefined }
                  ]
                }
              ]
            }
          ]
        }
      },
      showDetail: false,
      relationOptions: [
        {
          id: 'or',
          name: '或者'
        },
        {
          id: 'and',
          name: '并且'
        },
        {
          id: 'not',
          name: '否'
        }
      ],
      dateType: [],
      options: {
          "operations":[
              {
                  "code":"equals",
                  "symbol":"=",
                  "name":"等于",
                  "description":""
              },
              {
                  "code":"notequals",
                  "symbol":"!=",
                  "name":"不等于",
                  "description":""
              },
              {
                  "code":"contains",
                  "symbol":"in",
                  "name":"包含",
                  "description":""
              },
              {
                  "code":"greater",
                  "symbol":"&gt;",
                  "name":"大于",
                  "description":""
              },
              {
                  "code":"smaller",
                  "symbol":"&lt;",
                  "name":"小于",
                  "description":""
              },
              {
                  "code":"like",
                  "symbol":"like",
                  "name":"全模糊",
                  "description":""
              },
              {
                  "code":"likeleft",
                  "symbol":"likeleft",
                  "name":"左模糊",
                  "description":""
              },
              {
                  "code":"likeright",
                  "symbol":"likeright",
                  "name":"右模糊",
                  "description":""
              }
          ],
          "attributetype":{
              "string":{
                  "type":"string",
                  "description":"字符串",
                  "operation":[
                      "equals",
                      "notequals",
                      "like",
                      "likeleft",
                      "likeright"
                  ]
              },
              "date":{
                  "type":"date",
                  "format":"yyyy-MM-dd HH:mm:ss",
                  "description":"日期",
                  "operation":[
                      "equals",
                      "notequals",
                      "greater",
                      "smaller"
                  ]
              },
              "number":{
                  "description":"数值",
                  "operation":[
                      "equals",
                      "notequals",
                      "greater",
                      "smaller"
                  ]
              },
              "select":{
                  "type":"dictionary",
                  "description":"下拉代码",
                  "operation":[
                      "equals",
                      "notequals"
                  ]
              },
              "cascader":{
                  "type":"dictionary",
                  "description":"级联代码",
                  "operation":[
                      "equals",
                      "contains"
                  ]
              }
          },
          "attributedict":{
              "select":{
                  "status":"atSchoolStatus",
                  "ic_type":"id",
                  "political":"political",
                  "sex":"gender"
              },
              "cascader":{
                  "department":"department",
                  "type":"usertype"
              }
          },
          "attributeentries":[
              {
                  "code":"abcdx",
                  "name":"扩展字段测试",
                  "type":"string"
              },
              {
                  "code":"test",
                  "name":"测试",
                  "type":"string"
              },
              {
                  "code":"tttt",
                  "name":"tttt",
                  "type":"string"
              },
              {
                  "code":"longtext",
                  "name":"长字段",
                  "type":"string"
              },
              {
                  "code":"datetype",
                  "name":"日期类型",
                  "type":"date"
              },
              {
                  "code":"ic_number",
                  "name":"身份证号码",
                  "type":"string"
              },
              {
                  "code":"is_expired",
                  "name":"测试派生字段is_expired",
                  "type":"string"
              },
              {
                  "code":"is_locked",
                  "name":"测试派生字段is_locked",
                  "type":"string"
              },
              {
                  "code":"tag",
                  "name":"测试派生字段tag",
                  "type":"string"
              },
              {
                  "code":"uid",
                  "name":"测试派生字段uid",
                  "type":"string"
              },
              {
                  "code":"sex",
                  "name":"性别",
                  "type":"select",
                  "dict":"gender"
              },
              {
                  "code":"type",
                  "name":"人员类别",
                  "type":"cascader",
                  "dict":"usertype"
              },
              {
                  "code":"status",
                  "name":"在校状态",
                  "type":"select",
                  "dict":"atSchoolStatus"
              },
              {
                  "code":"department",
                  "name":"组织机构",
                  "type":"cascader",
                  "dict":"department"
              },
              {
                  "code":"ic_type",
                  "name":"证件类别",
                  "type":"select",
                  "dict":"id"
              },
              {
                  "code":"political",
                  "name":"政治面貌",
                  "type":"select",
                  "dict":"political"
              }
          ]
      },
      current_row: {},
      isAdd: false,
      submitStatus: false
    }
  },
  watch: {
    'itemForm.expressionContent': {
      handler: function (val) {
        let submitStatus = true
        let Names = ''
        for (let key in this.itemForm.expressionContent['c']) {
          let outItem = this.itemForm.expressionContent['c'][key]
          let outItemName = this.itemForm.expressionContent['c'].length > 1 ? '{' : ''
          for (let midKey in outItem['c']) {
            let midItem = outItem['c'][midKey]
            let midItemName = outItem['c'].length > 1 ? '[' : ''
            for (let insideKey in midItem['c']) {
              let insideItem = midItem['c'][insideKey]
              if (insideItem.f && insideItem.o && insideItem.v) {
                let item = midItem['c'].length > 1 ? '(' : ''
                item += `${this.getFname(insideItem.f)} ${this.getOname(insideItem.o)} ${this.getVname(insideItem.f, insideItem.v)}`
                item += midItem['c'].length > 1 ? ')' : ''
                if (insideKey < midItem['c'].length - 1) {
                  item += ` ${this.getLname(midItem['l'])} `
                }
                // console.log('item', item)
                midItemName += item
              } else {
                submitStatus = false
              }
            }
            midItemName += outItem['c'].length > 1 ? ']' : ''
            if (midKey < outItem['c'].length - 1) {
              midItemName += ` ${this.getLname(outItem['l'])} `
            }
            // console.log('midItemName', midItemName)
            outItemName += midItemName
          }
          outItemName += this.itemForm.expressionContent['c'].length > 1 ? '}' : ''
          if (key < this.itemForm.expressionContent['c'].length - 1) {
            outItemName += ` ${this.getLname(this.itemForm.expressionContent['l'])} `
          }
          // console.log('outItemName', outItemName)
          Names += outItemName
        }
        // console.log('Names', Names)
        this.itemForm.expressionName = Names
        this.submitStatus = submitStatus
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters([
      'user'
    ])
  },
  mounted () {
    this.boxHeight = document.body.clientHeight - 250
  },
  methods: {
    async deleteFilter (row) {
      this.loading = true
      let res = await Group.deleteFilter(row.id)
      this.loading = false
      if (!res['code']) {
        this.$message.success('删除成功！')
        this.getList()
        if (this.current_row.id === row.id) {
          this.init()
        }
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    getLname (data) {
      let value = this.relationOptions.find(item => {
        return item.id === data
      })
      return value.name
    },
    getFname (data) {
      let value = this.options.attributeentries.find(item => {
        return item.code === data
      })
      return value.name
    },
    getOname (data) {
      let value = this.options.operations.find(item => {
        return item.symbol === data
      })
      if (value) {
        return value.name
      } else {
        return data
      }
      // return value.name
    },
    getVname (type, data) {
      let typeItem = this.options.attributeentries.find(item => {
        return item.code === type
      })
      // console.log('typeItem', typeItem)
      if (typeItem['dict']) {
        if (typeItem['dict'] === 'department') {
          return this.departmentName[data]
        } else if (typeItem['dict'] === 'usertype') {
          return this.userTypeName[data]
        } else if (typeItem['dict'] === 'address') {
          return this.addressName[data]
        } else {
          var options = this.filters[typeItem['dict']]
          let value = options.find(item => {
            return item.id === data
          })
          return value.name
        }
      } else {
        return data
      }
    },
    async submitFilter () {
      this.itemForm = JSON.parse(JSON.stringify(this.itemForm))
      if (!this.itemForm.name) {
        this.$message.error('请填写名称!')
        return false
      }
      if (!this.submitStatus) {
        this.$message.error('请填写完整!')
        return false
      }
      if (!this.itemForm.type) {
        this.itemForm.type = 'standard'
      }
      let res = null
      this.loading = true
      if (this.isAdd) {
        res = await Group.addFilter(this.itemForm)
      } else {
        res = await Group.changeFilter(this.current_row.id, this.itemForm)
      }
      this.loading = false
      if (!res['code']) {
        this.$message.success('保存成功！')
        this.getList()
        if (this.isAdd) {
          this.init()
        }
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    init () {
      this.isAdd = false
      this.current_row = {}
      this.itemForm = {
        name: '',
        order: 0,
        type: 'standard',
        expressionName: '',
        expressionContent: {
          '@type': 'Complex',
          'l': 'and',
          'c': [
            {
              '@type': 'Complex',
              'l': 'and',
              'c': [
                {
                  '@type': 'Complex',
                  'l': 'and',
                  'c': [
                    { '@type': 'Basic', 'f': 'sex', 'o': undefined, 'v': undefined }
                  ]
                }
              ]
            }
          ]
        }
      }
    },
    addFilter () {
      this.addVisible = true
    },
    addClose () {
      this.addVisible = false
      this.addTypeForm.type = 'standard'
    },
    addSubmit () {
      this.isAdd = true
      this.addVisible = false
      this.itemForm = {
        name: '',
        order: 0,
        type: this.addTypeForm.type,
        expressionName: '',
        expressionContent: {
          '@type': 'Complex',
          'l': 'and',
          'c': [
            {
              '@type': 'Complex',
              'l': 'and',
              'c': [
                {
                  '@type': 'Complex',
                  'l': 'and',
                  'c': [
                    { '@type': 'Basic', 'f': 'sex', 'o': undefined, 'v': undefined }
                  ]
                }
              ]
            }
          ]
        }
      }
      this.addTypeForm.type = 'standard'
    },
    chooseList (row) {
      this.isAdd = false
      this.current_row = row
      this.getFilterDetail()
    },
    changeType(event, outIndex, midIndex, insideIndex) {
      let option = this.options['attributeentries'].find(item => {
        return item.code === event
      })
      // console.log(option)
      if (option.attributetype === 'dictionary') {
        this.itemForm.expressionContent['c'][outIndex]['c'][midIndex]['c'][insideIndex]['v'] = undefined
      } else if (option.attributetype === 'number') {
        this.itemForm.expressionContent['c'][outIndex]['c'][midIndex]['c'][insideIndex]['v'] = 0
      } else if (option.attributetype === 'date') {
        this.itemForm.expressionContent['c'][outIndex]['c'][midIndex]['c'][insideIndex]['v'] = []
      } else {
        this.itemForm.expressionContent['c'][outIndex]['c'][midIndex]['c'][insideIndex]['v'] = ''
      }
      this.itemForm.expressionContent['c'][outIndex]['c'][midIndex]['c'][insideIndex]['o'] = undefined
      this.itemForm = JSON.parse(JSON.stringify(this.itemForm))
    },
    addOut () {
      this.itemForm.expressionContent['c'].push({
        '@type': 'Complex',
        'l': 'and',
        'c': [
          {
            '@type': 'Complex',
            'l': 'and',
            'c': [
              { '@type': 'Basic', 'f': 'sex', 'o': undefined, 'v': undefined }
            ]
          }
        ]
      })
    },
    deleteOut (outIndex) {
      this.itemForm.expressionContent['c'].splice(outIndex, 1)
    },
    addMid (outIndex) {
      this.itemForm.expressionContent['c'][outIndex]['c'].push({
        '@type': 'Complex',
        'l': 'and',
        'c': [
          { '@type': 'Basic', 'f': 'sex', 'o': undefined, 'v': undefined }
        ]
      })
    },
    deleteMid (outIndex, midIndex) {
      this.itemForm.expressionContent['c'][outIndex]['c'].splice(midIndex, 1)
    },
    addInside (outIndex, midIndex) {
      this.itemForm.expressionContent['c'][outIndex]['c'][midIndex]['c'].push({
        '@type': 'Basic', 'f': 'sex', 'o': undefined, 'v': undefined
      })
      this.itemForm = JSON.parse(JSON.stringify(this.itemForm))
    },
    deleteInside (outIndex, midIndex, insideIndex) {
      this.itemForm.expressionContent['c'][outIndex]['c'][midIndex]['c'].splice(insideIndex, 1)
    },
    async getList () {
      this.loading = true
      let res = await Group.getFilterList()
      this.loading = false
      if (!res['code']) {
        this.listData = res['data']
        if (this.id) {
          let item = this.listData.find(item => {return Number(item.id) === Number(this.id)})
          if (item) {
            this.current_row = item
            this.isAdd = false
            this.getFilterDetail()
          }
          this.id = ''
        }
      }
    },
    async getFilterDetail () {
      this.showDetail = false
      this.loading = true
      let res = await Group.getFilterDetail(this.current_row.id)
      this.loading = false
      if (!res['code']) {
        this.itemForm = {
          name: res['data']['name'],
          order: res['data']['order'],
          type: res['data']['type'],
          expressionName: res['data']['expressionName'],
          expressionContent: res['data']['expressionContent']
        }
        setTimeout(() => {
          this.showDetail = true
        }, 0);
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    trunDepartmentData (data) {
      for (let k in data) {
        this.departmentName[data[k]['id']] = data[k]['name']
        if (data[k]['children']) {
          this.trunDepartmentData(data[k]['children'])
        }
      }
    },
    trunUserTypeData (data) {
      for (let k in data) {
        this.userTypeName[data[k]['id']] = data[k]['name']
        if (data[k]['children']) {
          this.trunUserTypeData(data[k]['children'])
        }
      }
    },
    trunAddressData (data) {
      for (let k in data) {
        this.addressName[data[k]['id']] = data[k]['name']
        if (data[k]['children']) {
          this.trunAddressData(data[k]['children'])
        }
      }
    },
    async getCode () {
      let res = await Group.getCode('basic')
      for (let key in res['data']) {
        this.filters[key] = res['data'][key]
      }
      let res1 = await Group.getCode('department')
      this.filters['department'] = res1['data']
      this.trunDepartmentData(res1['data'])
      let res2 = await Group.getCode('usertype')
      this.filters['usertype'] = res2['data']
      this.trunUserTypeData(res2['data'])
      let res3 = await Group.getFilterCode()
      this.options = res3['data']
      // console.log('this.options', this.options)
      var dateType = []
      this.options['attributeentries'].map(item => {
        if (item.type === 'runtime_date') {
          dateType.push(item.code)
        }
      })
      this.dateTyple = dateType
      // let res4 = await Group.getCode('filter')
      // this.options = res3['data']
      let res5 = await Group.getCode('address')
      this.filters['address'] = res5['data']
      this.trunAddressData(res5['data'])
      this.getList()
    }
  },
  created () {
    if (this.$route.query && this.$route.query.id) {
      this.id = this.$route.query.id
    }
    this.getCode()
  }
}
</script>

<style scoped>
.drawer-bar {
  padding: 8px 0 20px;
}

.depart-box {
  width: 49%;
}
.depart-l {
  border: 1px solid rgba(0,0,0,0.15);
  margin-right: 20px;
  width: 250px;
  padding: 5px;
}
.depart-r {
  width: calc(100% - 30% - 250px);
  min-width: 700px;
}
.depart-title {
  width: 100%;
  height: 30px;
  line-height: 30px;
  background: rgba(0,0,0,0.02);;
  border-bottom: 1px solid rgba(0,0,0,0.06);
}
.depart-title-l {
  margin-left: 24px;
}
.depart-title-r {
}
.depart-title-r span {
  margin-left: 15px;
  color: #0091FF;
  font-size: 12px;
}

.tree-node .fr{
  color: #1890FF;
  margin-right: 10px;
  display: none;
}
.depart-con .delete-icon {
  color: #1890FF;
}
.depart-con .delete-icon-default {
  color: rgba(0,0,0,0.25);
}
.ant-tree-node-content-wrapper:hover .fr {
  display: block;
}
.tree-node .fr i {
  margin-right: 10px;
}

.ant-form-item {
  width: 100%;
}
.right-form {
  margin-top: 5px;
}

.inside-box {
  display: flex;
}
.box-left {
  flex: 1 1 300px;
}
.box-right {
  flex: 0 0 30px;
  text-align: center;
  align-items: center;
  display: flex;
}
.box-right i {
  align-items: center;
  margin: 0 auto;
  cursor: pointer;
  margin-right: 5px;
}
.item-left {
  width: 33.33%;
}
.boxstyle {
  border: 1px solid rgba(0,0,0,0.15);
  padding: 10px 20px;
  border-radius: 8px;
  background: rgba(0,0,0,0.04);
  position: relative;
}
.inside-item {
  width: 100%;
  margin: 5px 0;
  border:none;
  border-bottom: 1px solid rgba(0,0,0,0.06);
  padding: 5px 0;
  background: none;
  border-radius: 0;
}

.left-con {
  margin-top: 10px;
  overflow-y: auto;
}
.left-con ul li {
  line-height: 28px;
  padding: 0 5px 0 0;
  cursor: pointer;
  width: 100%;
  overflow: hidden;
}
.left-con ul li.cur {
  background: #E6F7FF;
}
.left-con ul li i {
  display: none
}
.left-con ul li:hover {
  background: #E6F7FF;
}
.left-con ul li:hover i {
  display: block;
  margin-top: 8px;
  color: #1890FF;
}
.left-con ul li span.fl {
  display: block;
  width: 70%;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: auto;
  overflow: hidden;
}
.left-con ul li span.icon {
  display: flex;
  height: 28px;
  width: 20px;
  align-items: center;
  /* justify-content: center; */
}

.mid-box {
  background: #E6F7FF;
  border: 1px solid rgba(145,213,255,1);
}
.groupbox {
  background: #F6FFED;
  border: 1px solid rgba(183,235,143,1);
}
.add-btn {
  background: #E6F7FF;
  border: 1px dashed rgba(183,235,143,1);
  border-radius: 8px!important;
  color: #226FBB;
  font-size: 12px;
  background: #F6FFED;
}
.groupbox .add-btn {
  background: #E6F7FF;
  border: 1px dashed rgba(145,213,255,1);
}
.mid-box .add-btn {
  border-radius: 2px;
  background: #fff;
  border: 1px dashed rgba(0,0,0,0.15);
}

.close-circle-icon {
  color: #FF4D4F;
}
.close-circle-icon-default {
  /* color: #fff;
  background: rgba(0,0,0,0.25); */
}
.rightclose {
  position: absolute;
  right: 0;
  top: 0;
}
.rightclose i {
  margin: 0;
}
</style>
